<template>
  <div class="position">
    <v-progress-circular
      indeterminate
      :value="60"
      :color="color"
    ></v-progress-circular>
  </div>
</template>

<script>
import { BASE_BLACK_COLOR } from '../../../../../src/utils/constants';

export default {
  name: 'Loader',
  data() {
    return {
      color: BASE_BLACK_COLOR,
    };
  },
};
</script>

<style lang="scss" scoped>
.position {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0.7;
  background-color: white;
  z-index: 333;
}
</style>
